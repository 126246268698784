import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import type { Icon } from '@hyundai/assets';
import { DealerFeature, EventTrackingService, RouterLinkDirective } from '@hyundai/ng-common-lib';
import {
    ElementType,
    getGlobalImprintUrl,
    getGlobalLegalUrl,
    getGlobalPrivacyUrl,
    TrackingType,
} from '@hyundai/shared-lib';
import { Store } from '@ngrx/store';
import { getYear } from 'date-fns';
import { ABOUT_US_TABS } from '../../pages/about-us-page/about-us-infos';
import { FootnotesComponent } from '../footnotes/footnotes.component';

@Component({
    standalone: true,
    imports: [RouterLinkDirective, NgClass, FootnotesComponent],
    templateUrl: './footer-pw.component.html',
    selector: 'app-footer-pw',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterPwComponent {
    ElementType = ElementType;
    tracking = inject(EventTrackingService);
    ABOUT_US_TABS_JOBS = ABOUT_US_TABS.JOBS;

    getGlobalImprintUrl = getGlobalImprintUrl();
    getGlobalPrivacyUrl = getGlobalPrivacyUrl();
    getGlobalLegalUrl = getGlobalLegalUrl();

    store = inject(Store);
    dealer = this.store.selectSignal(DealerFeature.state.selectLoadedDealer);
    dealerContactMailLink = this.store.selectSignal(DealerFeature.state.selectDealerContactMailLink);
    dealerServiceMailLink = this.store.selectSignal(DealerFeature.state.selectDealerServiceMailLink);

    currentYear = getYear(new Date());

    serviceLinks = computed(() => {
        const dealer = this.dealer();
        const links: Array<{ icon: Icon; label: string; href: string; trackingType?: TrackingType }> = [
            {
                icon: 'icon-mail',
                label: 'Kontakt',
                href: this.dealerContactMailLink(),
                trackingType: TrackingType.CONTACT,
            },
        ];

        if (dealer.hasSales && dealer.pages.offerForm)
            links.push({
                icon: 'icon-offer',
                label: 'Angebot',
                href: dealer.pages.offerForm,
                trackingType: TrackingType.OFFER,
            });
        if (dealer.hasSales && dealer.pages.testDriveForm)
            links.push({
                icon: 'icon-wheel',
                label: 'Probefahrt',
                href: dealer.pages.testDriveForm,
                trackingType: TrackingType.TESTDRIVE,
            });
        if (dealer.hasService && dealer.pages.services)
            links.push({
                icon: 'icon-service',
                label: 'Servicetermin',
                href: this.dealerServiceMailLink(),
                trackingType: TrackingType.SERVICE,
            });

        return links;
    });

    socialLinks = computed(() => {
        const dealer = this.dealer();
        const links: Array<{ icon: Icon; href: string; label: string }> = [];

        if (dealer.socials.facebook)
            links.push({
                icon: 'icon-social-facebook',
                href: dealer.socials.facebook,
                label: 'Facebook verlinkung des Händler',
            });
        if (dealer.socials.instagram)
            links.push({
                icon: 'icon-social-instagram',
                href: dealer.socials.instagram,
                label: 'Instagram verlinkung des Händler',
            });
        if (dealer.socials.linkedIn)
            links.push({
                icon: 'icon-social-linkedin',
                href: dealer.socials.linkedIn,
                label: 'LinkedIn verlinkung des Händler',
            });
        if (dealer.socials.tiktok)
            links.push({
                icon: 'icon-social-tiktok',
                href: dealer.socials.tiktok,
                label: 'TikTok verlinkung des Händler',
            });
        if (dealer.socials.youtube)
            links.push({
                icon: 'icon-social-youtube',
                href: dealer.socials.youtube,
                label: 'Youtube verlinkung des Händler',
            });
        if (dealer.socials.x)
            links.push({ icon: 'icon-social-x', href: dealer.socials.x, label: 'X verlinkung des Händler' });

        return links;
    });
}
